<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <b-col class="d-lg-none d-block py-1 bg-white">
        <b-link class="d-flex justify-content-center">
          <b-img
            src="./../../assets/images/avatars/image.png"
            rounded
            fluid
            alt="profile photo"
            class="bg-white logo_img"
          />
        </b-link>
      </b-col>
      <b-col lg="6" class="d-none d-lg-flex align-items-start mt-0">
        <div class="d-lg-flex justify-content-center sticky-top">
          <div class="mb-5">
            <!-- Brand logo  for large device -->
            <b-link class="brand-logo pb-5">
              <img src="./../../assets/images/logo/vep-logo.png" alt="" />
            </b-link>
          </div>
          <!-- Left Text for large device-->
          <div class="p-5">
            <b-img fluid :src="imgUrl" alt="Register V2" />
          </div>
        </div>
      </b-col>
      <!-- Register-->
      <b-col lg="6" class="auth-bg px-2 p-lg-5 pt-lg-0 pt-2 pb-5 overflow-auto">
        <b-col sm="8" md="6" lg="8" class="px-xl-2 mx-auto">
          <b-card-title title-tag="h2" class="font-weight-bold mb-1" />
          <b-form>
            <div
              class="d-flex justify-content-center align-items-center container"
            >
              <div class="py-5 px-3">
                <h5 class="m-0 fs-4 fw-semibold">Verify Email</h5>
                <p>
                  We sent a verification code to your email. Enter the code from
                  the mobile in the field below.
                </p>
                <b class="mb-1 d-block fs-1">demo@gmail.com</b>
                <p class="m-0">type 6 digit code</p>
                <div class="d-flex flex-row">
                  <input type="text" class="form-control" autofocus="" /><input
                    type="text"
                    class="form-control"
                  /><input type="text" class="form-control" />
                  <input type="text" class="form-control" />
                  <input type="text" class="form-control" />
                  <input type="text" class="form-control" />
                </div>
                <div class="text-center mt-2">
                  <b-button
                    variant="primary"
                    block
                    type="submit"
                    @click.prevent="validationForm"
                  >
                    Verify my account
                  </b-button>
                  <div class="mt-2">
                    <span>Don't get the code? </span>
                    <span class="text-primary">Resend </span>
                    <span class="text-primary">or Call Us</span>
                  </div>
                </div>
              </div>
            </div>
          </b-form>
        </b-col>
      </b-col>
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import store from '@/store/index';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import VuexyLogo from '@core/layouts/components/Logo.vue';
import { togglePasswordVisibility } from '@core/mixins/ui/forms';
import { email, required } from '@validations';
import {
  BButton,
  BCardText,
  BCardTitle,
  BCol,
  BForm,
  BFormCheckbox,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BImg,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BRow,
} from 'bootstrap-vue';
import { ValidationObserver, ValidationProvider } from 'vee-validate';

export default {
  components: {
    VuexyLogo,
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    // validations
    ValidationProvider,
    ValidationObserver,
    BFormDatepicker,
    BFormTextarea,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      username: '',
      userEmail: '',
      password: '',
      sideImg: require('@/assets/images/pages/register-v2.svg'),
      // validation
      required,
      email,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/register-v2-dark.svg');
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  methods: {
    validationForm() {
      this.$refs.registerForm.validate().then((success) => {
        if (success) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Form Submitted',
              icon: 'EditIcon',
              variant: 'success',
            },
          });
        }
      });
    },
  },
};
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
.button {
  background-color: rgb(3, 77, 133);
  color: #fff;
}

.form-control {
  margin-right: 12px;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #ff8880;
  outline: 0;
  box-shadow: none;
}

.cursor {
  cursor: pointer;
}
.logo_img {
  width: 120px !important;
  margin-left: -80px;
}
</style>
